// SAN Configuration

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

import { getAuth } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyCBaYfi5rbYkbCwEtr8J9QDtBo30-2GeE8',
  authDomain: 'mlesan.firebaseapp.com',
  projectId: 'mlesan',
  storageBucket: 'mlesan.appspot.com',
  messagingSenderId: '922517892766',
  appId: '1:922517892766:web:90cc0b9f28d7135290958f',
}


const firebaseApp = initializeApp(config)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const auth = getAuth()

export { db, storage, auth }

export default {
  db,
  storage,
  auth,
}

